/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse, Pagination } from '@eagle/api-types';
import { Feature, FeatureType, Thing } from '@eagle/core-data-types';
import { FeatureTypes, locationRelatedEventTypes, ThingEvent } from '@eagle/data-function-types';
import { Alert, AppliedFilter, CacheDataTypes, EventsTable, EVENT_DATA_MAX_DAYS, FILTER_SELECT_ALL_FLAG, FindItemsDeferredPaginatedResponse, MiddleSpinner, Query, SelectionCheckBox, SelectionCheckBoxGroup, useAuthenticated, useBoolFlag, useFetchAllCache, useFlags, usePromise, useSmallScreen } from '@eagle/react-common';
import { Cancel } from '@mui/icons-material';
import { Chip, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import Axios from 'axios';
import { DateTime } from 'luxon';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_DATA_MAX_DAYS_FLAG } from '../thing-detail';
import { ThingEventBase } from './thing-event-base';
import { ThingEventInvestigateButton } from './thing-event-investigate-button';

type ThingEventFilter = Pick<ThingEvent, 'feature' | 'eventTypeId' | 'featureTypeId'>

export const DataDrivenThingEvents: FC<{ thing: Thing }> = ({ thing }) => {
  const { axios } = useAuthenticated();
  const [activeFilters, setActiveFilters] = useState<ThingEventFilter[]>([]);
  const { t } = useTranslation(['common']);
  const hasTrackThingHistoryFlag = useBoolFlag('track-thing-history-feature');
  const flags = useFlags();
  const maxDays = flags[EVENT_DATA_MAX_DAYS_FLAG] as number || EVENT_DATA_MAX_DAYS;

  const myFeatureCache = useFetchAllCache(CacheDataTypes.MY_FEATURE);
  const featureTypeCache = useFetchAllCache(CacheDataTypes.FEATURE_TYPE);
  const [myFeatures, myFeaturesError, myFeaturesState] = usePromise<Feature[]>(
    myFeatureCache.all(),
    [myFeatureCache],
  );
  const [featureInstances, featureInstancesError, featureInstancesState] = usePromise<string[]>(
    async () => {
      const result = await axios.get<string[]>('/api/v1/my/feature-instances');
      return result.data.sort();
    },
    [axios],
  );
  const [featureTypes, featuresError, featuresState] = usePromise<FeatureType[]>(
    featureTypeCache.all(),
    [featureTypeCache],
  );

  const handleQueryChanged = useCallback(({ filters }: Query, pagination: Pagination): FindItemsDeferredPaginatedResponse<ThingEvent> => {
    const cancelToken = Axios.CancelToken.source();
    return {
      cancel: () => cancelToken.cancel(),
      promise: axios.get<PaginatedResponse<ThingEvent>>(`/api/v2/thing-event/thing/${thing._id}`, {
        cancelToken: cancelToken.token,
        params: {
          dateRangeStart: DateTime.now().minus({ days: maxDays }).toUTC().toISO(),
          filter: { '$or': simplifyFilter(filters as unknown as ThingEventFilter[]) },
          limit: 5,
          skip: pagination.skip,
          sort: { occurred: -1 },
        },
      }).then((response) => {
        return response.data;
      }),
    };
  }, [axios]);

  if ([myFeaturesState, featureInstancesState, featuresState].includes('pending')) {
    return <MiddleSpinner />;
  }

  const hasError = myFeaturesError || featureInstancesError || featuresError;

  return (
    <ThingEventBase
      isFiltering={activeFilters.length > 0}
      headerContent={
        <DataDrivenThingEventFilter
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          features={myFeatures ?? []}
          featureTypes={featureTypes ?? []}
          featureInstances={featureInstances ?? []}
        />
      }
      maxDays={maxDays}
    >
      {hasError ? (
        <Alert sx={{ m: 2 }} severity='error'>{t('common.hint.error')}</Alert>
      ) : (
        <EventsTable
          appliedFilters={activeFilters as unknown as AppliedFilter[]}
          fixHeight={true}
          hideCategory
          onQueryChanged={handleQueryChanged}
          rowChildren={hasTrackThingHistoryFlag ? (
            (event) => (
              <ThingEventInvestigateButton
                event={event}
              />
            )
          ) : undefined}
          thing={thing}
        />
      )}
    </ThingEventBase>
  );
};

const MAX_NUMBER_OF_CHIPS = 3;

interface DataDrivenThingEventFilterProps {
  activeFilters: ThingEventFilter[];
  setActiveFilters: Dispatch<SetStateAction<ThingEventFilter[]>>;
  features: Feature[];
  featureTypes: FeatureType[];
  featureInstances: string[];
}

const DataDrivenThingEventFilter: FC<DataDrivenThingEventFilterProps> = ({ activeFilters, setActiveFilters, features, featureTypes, featureInstances }) => {
  const smallScreen = useSmallScreen();
  const { t, i18n } = useTranslation(['common', 'track']);
  const hasFilterSelectAllFlag = useBoolFlag(FILTER_SELECT_ALL_FLAG);

  const getEventLabel = (featureTypeId: string, event: string): string => {
    const key = `common:event-descriptions-v2.${featureTypeId}.${event}.label`;
    if (i18n.exists(key)) {
      return t(key);
    }
    return event;
  };

  const options = useMemo(() => {
    const output = [];

    for (const instance of featureInstances) {
      const [featureId, ...instanceParts] = instance.split('/');
      const instanceName = instanceParts.join('.');
      const instanceKey = `common:feature-instances.${featureId}.${instanceName}`;

      const feature = features.find((feature) => feature._id === featureId);
      const featureType = featureTypes.find((featureType) => featureType._id === feature?.featureTypeId);
      if (!featureType) {
        continue;
      }
      const events = Object.keys(featureType.events)
        .filter((event) => !locationRelatedEventTypes.includes(event))
        .sort((a, b) => {
          return getEventLabel(featureType._id, a).localeCompare(getEventLabel(featureType._id, b));
        });
      output.push({ feature: instance, featureTypeId: featureType._id, events, label: i18n.exists(instanceKey) ? t(instanceKey) : t(`common:features.${instance}`) });
    }

    return output.sort((a, b) => a.label.localeCompare(b.label));
  }, [featureInstances, features, featureTypes, t]);

  const getSelectedLabel = (event: ThingEventFilter): string => {
    const [featureId, ...instanceParts] = event.feature.split('/');
    const instanceName = instanceParts.join('.');
    const instanceKey = `common:feature-instances.${featureId}.${instanceName}`;
    const featureLabel = i18n.exists(instanceKey) ? t(instanceKey) : t(`common:features.${event.feature}`);
    const eventLabel = getEventLabel(event.featureTypeId, event.eventTypeId);

    return event.featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? `${featureLabel} – ${eventLabel}` : `${eventLabel} – ${featureLabel}`;
  };

  const numberOfOptions = options.reduce((result, option) => result + option.events.length, 0);
  const isAllSelected = activeFilters.length === numberOfOptions;

  const toggleSelectAll = (): void => {
    if (isAllSelected) {
      setActiveFilters([]);
      return;
    }
    const allValues = options.flatMap((option) => option.events.map((event) => ({
      feature: option.feature,
      eventTypeId: event,
      featureTypeId: option.featureTypeId,
    })));
    setActiveFilters(allValues);
  };

  return (
    <FormControl size='small'>
      {activeFilters.length === 0 && <InputLabel shrink={false}>{t('common:component.events.labels.event-filter')}</InputLabel>}
      <Select
        fullWidth
        multiple
        MenuProps={{
          sx: {
            maxWidth: 350,
            maxHeight: 400,
          },
          onClick: (e) => { e.stopPropagation(); },
        }}
        renderValue={(values) => (
          <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
            {values.slice(0, MAX_NUMBER_OF_CHIPS).map((value, i) => (
              <Chip
                key={i}
                size="small"
                onDelete={() => {
                  setActiveFilters((selected) => {
                    const newValue = selected.filter(({ feature, eventTypeId }) => !(feature === value.feature && eventTypeId === value.eventTypeId));
                    return newValue;
                  });
                }}
                sx={{ maxWidth: '100%' }}
                deleteIcon={<Cancel onMouseDown={(e) => { e.stopPropagation(); }} />}
                label={getSelectedLabel(value)}
              />
            ))}
            {values.length > MAX_NUMBER_OF_CHIPS && <Typography component="span" variant="body2">+{values.length - MAX_NUMBER_OF_CHIPS}</Typography>}
          </Stack>
        )}
        sx={{ width: smallScreen ? '100%' : 250 }}
        value={activeFilters}
      >
        {hasFilterSelectAllFlag && (
          <MenuItem divider sx={{ p: 0 }}>
            <SelectionCheckBox
              checked={isAllSelected}
              handleClick={toggleSelectAll}
              label={<strong>{t('common:component.filter-dropdown.labels.select-all')}</strong>}
            />
          </MenuItem>
        )}
        {options.map((option) => (
          <SelectionCheckBoxGroup
            key={option.feature}
            itemsChecked={activeFilters.filter(({ feature }) => feature === option.feature)?.map(({ eventTypeId }) => eventTypeId)}
            groupDisplay={option.label}
            subItemDisplay={(subItem) => getEventLabel(option.featureTypeId, subItem)}
            subItems={option.events}
            updateSelectedValue={(eventTypeIds) => {
              setActiveFilters((selected) => {
                const newValue = selected.filter(({ feature }) => feature !== option.feature);
                newValue.push(...eventTypeIds.map((eventTypeId) => ({ feature: option.feature, eventTypeId, featureTypeId: option.featureTypeId })));
                return newValue;
              });
            }} />
        ))}
      </Select>
    </FormControl>
  );
};

type SimplifiedThingEventFilter = Omit<ThingEventFilter, 'eventTypeId'> & {
  eventTypeId: string | { '$in': string[] };
}

const simplifyFilter = (data: ThingEventFilter[]): SimplifiedThingEventFilter[] => {
  const output: SimplifiedThingEventFilter[] = [];
  for (const item of data) {
    const { feature, eventTypeId, featureTypeId } = item;
    const existing = output.find((data) => data.feature === feature && data.featureTypeId === featureTypeId);
    if (existing) {
      if (typeof existing.eventTypeId === 'string') {
        existing.eventTypeId = { $in: [existing.eventTypeId, eventTypeId] };
      }
      else {
        existing.eventTypeId.$in.push(eventTypeId);
      }
    }
    else {
      output.push({ feature, featureTypeId, eventTypeId });
    }
  }
  return output;
};
